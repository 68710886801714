import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "../App.css";
import { fontWeight } from "@mui/system";
import "./Dates.css"; // Import the external CSS file

export default function Dates() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="dates-container">
      <div className="dates-header">
        <h1>Important Dates</h1>
        <div className="marquee">
          <marquee scrollamount="18">
            The INSPECT-2025 (INtelligent Signal Processing and Effective
            Communication Technologies) conference will be held at ABV-IIITM,
            Gwalior during 7th and 8th November 2025.
          </marquee>
        </div>
      </div>
      <Box className="dates-tabs-container">
        <TabContext value={value}>
          {/* <TabList onChange={handleChange} aria-label="important date tabs">
            <Tab label="Submission & Decision Dates" value="1" />
            {/* <Tab label="Second Round Submission" value="2" /> */}
            {/* <Tab label="Registration and Camera Ready" value="3" /> */}
          {/* </TabList> */} 
          <TabPanel value="1" className="tab-panel-background">
            <div className="tab-body">
              {/* <div className="tablist-inner-div">
                <div className="tab-body-content">
                  Submission Deadline: <strike>July 31, 2025</strike>
                </div>
                <div className="tab-body-content">
                  Extended Deadline: <strike>August 15, 2025</strike>
                </div>
                <div className="tab-body-content">
                  Final Deadline: <strike>August 31, 2025</strike>
                </div>
                <div className="tab-body-content">
                  Notification of Acceptance: September 10, 2025
                </div>
                <div className="tab-body-content">
                  Early Bird Registration: 20 days from acceptance email / October 10, 2025 (Whichever is earlier)
                </div>
                <div className="tab-body-content">
                  Camera Ready Submission Deadline: September 30, 2025
                </div>
                <div className="tab-body-content">
                  Early Bird Registration: October 10, 2025
                </div>
                <div className="tab-body-content">
                  Late Registration Deadline: October 30, 2025
                </div>
              </div> */}
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
  <tbody>
    {/* <tr>
      <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Round 2</td>
      <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Round 1</td>
    </tr> */}
    <tr>
      <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Submission Deadline: August 15, 2025</td>
      {/* <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Paper Submission Deadline: Closed</td> */}
    </tr>
    <tr>
      <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Decision Notification: September 15, 2025</td>
      {/* <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Decision Notification: Notification already sent</td> */}
    </tr>
    <tr>
      <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Early Bird Registration: September 30, 2025</td>
      {/* <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Early Bird Registration: October 10, 2025</td> */}
    </tr>
    <tr>
      <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Camera Ready Submission: September 30, 2025</td>
      {/* <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Camera Ready Submission: October 20, 2025</td> */}
    </tr>
    <tr>
      <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Late Registration Deadline: October 20, 2025</td>
      {/* <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>Late Registration Deadline: October 30, 2025</td> */}
    </tr>
    <tr>
      <td style={{ border: '1px solid white', padding: '8px', color: 'white', fontWeight: 'bold' }}>INSPECT-2025: November 07-08, 2025</td>
    </tr>
  </tbody>
</table>

            </div>
          </TabPanel>
          {/* <TabPanel value="2" className="tab-panel-background">
            <div className="tab-body">
              <div className="tablist-inner-div">
                <div className="tab-body-content">
                  Submission Deadline: July 15, 2025
                </div>
                <div className="tab-body-content">
                  Notification of Acceptance: October 15, 2025
                </div>
              </div>
            </div>
          </TabPanel> */}
          {/* <TabPanel value="3" className="tab-panel-background">
            <div className="tab-body">
              <div className="tablist-inner-div">
                <div className="tab-body-content">
                  Camera Ready Submission Deadline: September 25, 2025
                </div>
                <div className="tab-body-content">
                  Early Bird Registration: October 10, 2025
                </div>
                <div className="tab-body-content">
                  Late Registration Deadline: October 30, 2025
                </div>
              </div>
            </div>
          </TabPanel> */}
        </TabContext>
      </Box>
    </div>
  );
}
